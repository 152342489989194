.mobile-nav-bar-container {
    display: none;
    position: absolute;
    width: 100%;
    height: 0px;
    background: #5826e1f3;
    z-index: 50;
    transition: height 400ms;
}

.mobile-nav-list {
    list-style: none;
    opacity: 0;
    visibility: hidden;
}

.mobile-nav-active {
    height: 450px;
}

.mobile-nav-active > .mobile-nav-list {
    opacity: 1;
    transition: opacity 800ms;
    visibility: visible;
}

.mobile-nav-list > li {
    margin-bottom: 25px;
    width: 200px;
    font-size: 20px;
    color: white;
    font-weight: 500;
    transition: text-shadow 0.15s linear, font-weight 0.15s ease-in-out;
    cursor: pointer;
}

.mobile-nav-list > li:hover {
    text-shadow: 2px 1.5px #FF3C68;
    font-weight: 600;
}

.mobile-nav-list li .link-active {
    text-shadow: 2px 1.5px #FF3C68;
    font-weight: 600;
}

.mobile-nav-icon {
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
}

.mobile-nav-icon > svg {
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .mobile-nav-bar-container {
        display: flex;
        flex-direction: column;
    }

    .mobile-nav-list {
        text-align: center;
        margin: auto;
    }
}