.banner-text {
    color: #FFF;
    margin-right: 100px;
    width: 550px;
    z-index: 3;
    animation: transitionX 0.75s;
}

.banner-text > h2 {
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: 600;
    text-shadow: 3px 4px #FF3C68;
}

.banner-text > h3, .animated-text > h3, .animated-text > span {
    font-size: 35px;
    margin-bottom: 10px;
    font-weight: 600;
}

.animated-text > span {
    color: #FF3C68;
    font-weight: 700;
}

.animated-text {
    display: flex;
}

.animated-text > h3 {
    margin-right: 10px;
}

.banner-text > a > button {
    margin-top: 15px;
    background: #FF3C68;
    color: white;
    padding: 10px 20px;
    border: none;
    font-size: 17px;
    cursor: pointer;
    transition: box-shadow 0.2s linear, transform 0.2s linear, font-weight 0.2s linear;
}

.banner-text > a > button:hover {
    box-shadow: 5px 5px black;
    transform: translateX(-5px);
    font-weight: 700;
}


@media screen and (max-width: 1000px) {
    .banner-text {
        margin-right: 30px;
    }
}

@media screen and (max-width: 900px) {
    .banner-text {
        margin-right: 0px;
        text-align: center;
    }

    .animated-text {
        display: block;
    }

    .animated-text > h3, .animated-text > span {
        display: inline-block;
    }
}

@media screen and (max-width: 510px) {
    .banner-text > h2 {
        font-size: 13vw;
        margin-bottom: 13px;
    }
    
    .animated-text > h3, .animated-text > span {
        font-size: 7.5vw;
    }

    .banner-text > h3 {
        font-size: 8.5vw;
        margin-bottom: 13px;
    }
}