.home-page-container {
    position: relative;
    overflow-x: hidden;
}

@keyframes transitionX {
    from {
        opacity: 0;
        transform: translateX(-60px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes transitionY {
    from {
        opacity: 0;
        transform: translateY(-60px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
