.section-list-item {
    display: flex;
    position: relative;
    align-items: center;
    margin: auto;
    margin-bottom: 45px;
    animation: transitionY 0.75s;
}

.section-list-item:last-child {
    margin-bottom: 0;
}

.section-list-item-image {
    margin-right: 50px;
    height: 100%;
}

.section-list-item-image > img {
    width: 500px;
    height: 100%;
    border: 1px solid #FF3C68;
}

@media screen and (max-width: 1200px){
    .section-list-item {
        width: 500px;
        flex-direction: column;
    }

    .section-list-item-image {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .section-list-item-image > img {
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    .section-list-item {
        width: 100%;
    }
}

