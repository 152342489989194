.skill {
    display: none;
    margin: 20px 5%;
    animation: transitionY 2s;
}

.skill-active {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill > p {
    font-size: 18px;
    margin-top: 10px;
}

@media screen and (max-width: 1400px) {
    .skill {
        margin: 20px;
    }
}

@media screen and (max-width: 550px) {
    .skill > img {
        width: 100px;
    }
}