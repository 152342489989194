.banner-container {
    background: #5826E1;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 900px) {
    .banner-container {
        flex-direction: column;
    }
}



