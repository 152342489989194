.item-title > h3 {
    margin-bottom: 15px;
    font-size: 23px;
    font-weight: 500;
}

.item-highlight {
    color: #FF3C68;
    font-weight: 500;
    width: 70%;
}

.item-desc {
    font-size: 14px;
}

.item-date {
    font-weight: 600;
    margin-right: 15px;
    background-color: rgba(31, 31, 31, 0.829);
    color: white;
    border-radius: 20px;
    padding: 8px;
    font-size: 14px;
    width: 120px;
    text-align: center;
}

.item-date-highlight-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-title h3 {
    width: 70%;
    margin-right: 5px;
}

@media screen and (max-width: 600px) {
    .item-date {
        width: 140px;
    }

    .item-title {
        margin-bottom: 10px;
    }
}
