.contact-form {
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: transitionY 0.75s;
}

.contact-form > p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
}

.contact-form > button {
    background: #FF3C68;
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
    transition: box-shadow 0.2s linear, transform 0.2s linear, font-weight 0.2s linear;
    margin-top: 10px;
}

.contact-form > button:hover {
    box-shadow: 4px 4px #000;
    transform: translateX(-5px);
    font-weight: 700;
}

.success-noti {
    background: rgba(26, 240, 26, 0.712);
    color:  rgb(14, 162, 14);
    border: 2px solid rgb(14, 162, 14);
    border-radius: 5px;
    font-weight: 600;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 15px;
    display: none;
}

.noti-active {
    display: block;
    animation: transitionX 0.75s;
}

@media screen and (max-width: 800px) {
    .contact-form {
        margin-left: 0;
        margin-top: 30px;
    }
}

@media screen and (max-width: 600px) {
    .contact-form > p {
        width: 70vw;
    }
}
