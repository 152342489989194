.contact-info-container {
    animation: transitionX 0.75s;
}

.contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.contact-info > .contact-text {
    margin-left: 5px;
    color: black;
    text-decoration: none;
    transition: color 200ms linear;
}

.contact-text:hover {
    color: #FF3C68;
}

.social-info {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-info svg {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}