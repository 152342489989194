.skills-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skills-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 60%;
    margin: auto;
}

@media screen and (max-width: 900px) {
    .skills-container {
        width: 80%;
    }
}

@media screen and (max-width: 630px) {
    .skills-container {
        width: 95%;
        justify-content: space-around;
    }
}
