.banner-image {
    z-index: 10;
    animation: transitionX 0.75s;
}

@media screen and (max-width: 900px) {
    .banner-image {
        display: none;
        position: absolute;
        top: 60px;
        right: 80px; 
    }

    .banner-image > img {
        width: 180px;
    }
}