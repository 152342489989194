.banner-skills-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    z-index: 2;
}

.skill-circle {
    background: #fff;
    opacity: 60%;
    width: 70px;
    height: 70px;
    margin: auto;
    border-radius: 100%;
    position: absolute;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-circle > img {
   position: absolute;
   top: 0;
   bottom: 0;
   width: 60%;
   margin: auto;
}