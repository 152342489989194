.section-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-list-items-container {
    width: 70%;
}

@media screen and (max-width: 1600px) {
    .section-list-items-container {
        width: 85%;
    }
}
