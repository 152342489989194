.nav-bar-container {
    position: absolute;
    top: 40px;
    left: 80px;
    z-index: 10;
    color: white;
    font-weight: 500;
    display: flex;
    animation: transitionY 0.75s;
}

.nav-bar-container p {
    margin-right: 25px;
    font-size: 17px;
    cursor: pointer;
    border-bottom: 0px solid #FF3C68;
    padding-bottom: 3px;
    transition: border 0.15s linear, border-radius 0.15s linear, text-shadow 0.15s linear, font-weight 0.15s ease-in-out;
}

.nav-bar-container p:hover {
    border-bottom: 5px solid #FF3C68;  
    border-radius: 5px;
    text-shadow: 2px 1.5px #FF3C68;
    font-weight: 600;
}

.link {
    text-decoration: none;
    color: white;
}

.link-active p {
    border-bottom: 5px solid #FF3C68;  
    border-radius: 5px;
    text-shadow: 2px 1.5px #FF3C68;
    font-weight: 600;
}

@media screen and (max-width: 1100px) {
    .nav-bar-container {
        display: none;
    }
}