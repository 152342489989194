.section-button {
    background: #FF3C68;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    width: 120px;
    transition: box-shadow 0.2s linear, transform 0.2s linear, font-weight 0.2s linear;
    margin-top: 10px;
}

.section-button:hover {
    box-shadow: 4px 4px #000;
    transform: translateX(-5px);
    font-weight: 700;
}

@media screen and (max-width: 1200px) {
    .section-button {
        margin-top: 4px;
    }
}
